import type { RenewalOfferDecisionUnionFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import RENEWAL_OFFER_PRICING_FRAGMENT from './RenewalOfferPricing';
import RENEWAL_OFFER_TERM_FRAGMENT from './RenewalOfferTerm';
import USER_SUMMARY_FRAGMENT from './UserSummary';

const RENEWAL_OFFER_DECISION_FRAGMENT: TypedDocumentNode<RenewalOfferDecisionUnionFragment> = gql`
  fragment RenewalOfferDecisionUnion on RenewalOfferDecisionUnion {
    __typename
    ... on RenewalOfferDecision {
      id
      decidedByUser {
        ...UserSummary
      }
      decidedAt
      decision
      interestedTerm {
        ...RenewalOfferPricingTerm
      }
      pricing {
        ...RenewalOfferPricing
      }
      eSignature {
        id
        status
        signedUsers {
          id
        }
      }
    }
    ... on ExternalRenewalOfferDecision {
      id
      decision
      externalDecidedAt: decidedAt
      externalInterestedTerm: interestedTerm {
        lengthInMonths
        baseRent
      }
    }
  }
  ${RENEWAL_OFFER_PRICING_FRAGMENT}
  ${RENEWAL_OFFER_TERM_FRAGMENT}
  ${USER_SUMMARY_FRAGMENT}
`;

export default RENEWAL_OFFER_DECISION_FRAGMENT;
