import type { LeaseFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import MOVEOUT_INFO_FRAGMENT from './MoveOutInfo';
import RESIDENT_FRAGMENT from './Resident';
import UNIT_FRAGMENT from './Unit';

const LEASE_FRAGMENT: TypedDocumentNode<LeaseFragment> = gql`
  fragment Lease on Lease {
    id
    currentRent
    fromDate
    toDate
    status
    monthToMonth
    noticeGivenDate
    unit {
      ...Unit
    }
    othersOnLease {
      ...Resident
    }
    moveOutInfo {
      ...MoveOutInfo
    }
  }
  ${UNIT_FRAGMENT}
  ${RESIDENT_FRAGMENT}
  ${MOVEOUT_INFO_FRAGMENT}
`;

export default LEASE_FRAGMENT;
